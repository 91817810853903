import * as React from 'react';
import { ContactForm } from '../components/shared/contactForm';
import getTranslations from '../utils/getTranslations';

const ContactPage: React.FC = () => {
  const { contact } = getTranslations();

  return (
    <div>
      <ContactForm
        title={contact!.title!}
        subtitle={contact!.subtitle!}
        mailTo='support@beefit.io'
      />
    </div>
  );
};

export default ContactPage;
